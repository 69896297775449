import { Helmet } from 'react-helmet-async';
import logo from './logo.png';
import './App.css';

function App() {
  const Style1 = {
    padding: "5px 20px",
    color: "orange",
    fontFamily: "Montserrat",
  };

  const Style2 = {
    fontSize: "15px",
    padding: "2px 30px 2px 30px",
    color: "white",
    fontFamily: "Montserrat",
  };
  const problem_statement = "Residents find it challenging to book facilities (e.g. badminton courts) as they will need to navigate and search the indiv. venues for available slots amid intense competition."
  const proposed_solution = "To improve the search experience of our residents by leveraging Gen AI to perform a search based on location and date. Thereafter, the booking (including payment) of facilities will continue under onepa.gov.sg."

  return (
    <div>
      <Helmet>
        <meta
            httpEquiv="Content-Security-Policy"
            content={`
                        default-src 'self' https://*.mol-vica.com/;
                        script-src https://*.mol-vica.com/ https://*.gov.sg/ https://www.gstatic.com/recaptcha/ https://www.google.com/recaptcha/;
                        style-src 'self' 'unsafe-inline' https://*.mol-vica.com/ https://*.gov.sg/;
                        img-src 'self' https://*.mol-vica.com/ https://*.gov.sg/;
                        connect-src wss://*.mol-vica.com/ https://*.mol-vica.com/;
                  `}
          ></meta>
      </Helmet>
      <div>
        <img src={logo} alt="Logo" width={150} height={45}  />
        <h1 style={Style1}>Facilities Recommender</h1>
        <h3 style={Style1}>Problem Statement</h3>
          <li style={Style2}>{problem_statement}</li>
        <h3 style={Style1}>Proposed Solution</h3>
          <li style={Style2}>{proposed_solution}</li>
          <h3 style={Style1}>Sample Prompt</h3>
          <p style={Style2}>You must provide booking Type, a Place in Singapore and Date to query facility availability</p>
          <li style={Style2}>badminton court in Jalan Besar next Friday</li>
          <li style={Style2}>badminton court, near Jalan Besar, 1 Mar</li>
          <li style={Style2}>badminton court, 208581, coming weekend</li>
          <br/>
        </div>
    </div>
  );
}

export default App;
